<!-- 双色球复式中奖计算 -->
<template>
  <div class="">
    <ssqfu :fu3="ssq"></ssqfu>
    <div class="clear"></div>
  
   
  </div>
</template>

<script>
//import '../js/mpublic.js' //js文件引入，注意路径
import ssqfu from "@/components/jin/fu.vue";
//import api from '@/js/method'
export default {
  name: "",
  components: {
    ssqfu,
    
  },
  data() {
    // 数据源
    return {
      ssq: [{"wanfa":'ssq',"xh":"红球","xl":"蓝球","zh":"中红","zl":"中蓝",'xd':'红胆','xt':'红拖','zd':'中胆','zt':'中拖','xtl':'蓝球','ztl':'中蓝','pic':require('@/assets/jpg/ssq.jpg')}],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    // 函数体部分，js的主要逻辑控制
  
  },
  props: {},
};
</script>


<style scoped>
/* @import './swiper.css'; */

.clear {
  clear: both;
}
</style>